import React, { useState } from 'react';
import {Link} from "react-router-dom";

const MainPageNav = () => {
    const onClickSection = (e, id) => {
        e && e.preventDefault(); // to avoid the link from redirecting
        const elementToView = document.getElementById(id);
        elementToView.scrollIntoView();
        handleClick();
    }

    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    return (
        <ul className="mainmenu">
            <li><Link to="#services" onClick={(e=>onClickSection(e, 'services'))}>Services</Link></li>
            
            <li><Link to="#expertise" onClick={(e=>onClickSection(e, 'expertise'))}>Our Expertise</Link></li>

            <li><Link to="/staking">Staking</Link></li>

            <li><Link to="#contact" onClick={(e=>onClickSection(e, 'contact'))}>Contact</Link></li>
        </ul>
    )
}
export default MainPageNav;
