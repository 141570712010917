import React from 'react';

const Elrond = () => {
    return (
        <div className="rwt-about-area">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/egld.png" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">The internet scale Blockchain</h2>
                                <p>Elrond is one of the most promising blockchain projects in the crypto space. It's a highly scalable, fast and secure blockchain platform for distributed apps, enterprise use cases and the new internet economy. Elrond is built by a team of experienced entrepreneurs, engineers and researchers with significant blockchain backgrounds and technical experience and has one of the most engaged and vibrant communities in the whole crypto space.</p>
                                <div className="read-more-btn mt--40">
                                    <a className="btn-default" href="https://explorer.elrond.com/identities/cowrybase" target="_blank" rel="noreferrer"><span>Our Validators</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default Elrond
