import React from 'react';
import { FiCloud, FiCode, FiTerminal } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <FiCode />,
        title: 'Smart Contracts',
        description: 'Do you want to leverage the power of Blockchain and Web3 to improve your processes or want to explore completely new market opportunities? We can help by developing smart contracts tailored to your requirements - in Solidity.'
    },
    {
        icon: <FiCloud />,
        title: 'Staking Infrastructure',
        description: 'Do you need expert advisory in setting up your own staking infrastructure for a Proof of Stake network? We can help using our extensive experience in setting up validator nodes for various major PoS protocols.'
    },
    {
        icon: <FiTerminal />,
        title: 'Software Development',
        description: 'We can help your business by evaluating how your critical processes can be optimized using various software technologies and architectures and advise during the design, implementation, test and deployment of your solution.'
    },
]

const CowrybaseServices = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default CowrybaseServices;