import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Azero from '../about/azero';
import Elrond from '../about/Elrond';

const ValidatorsTab = () => {
    return (
        <div>
            <Tabs>
                <div className="row row--30">
                    <div className="order-2 order-lg-1 col-lg-4 col-md-12 col-sm-12 col-12 mt_md--30 mt_sm--30">
                        <div className="advance-tab-button advance-tab-button-1">
                            <TabList className="tab-button-list">
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Elrond Network</h4>
                                        <p className="description">A highly scalable, fast and secure blockchain platform for distributed apps, enterprise use cases and the new internet economy.</p>
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Aleph Zero</h4>
                                        <p className="description">Enterprise-grade public blockchain with instant finality and privacy based on Zero-knowledge Proofs (ZKPs) and secure Multi-Party Computation (sMPC).</p>
                                    </div>   
                                </Tab>
                            </TabList>
                        </div>
                    </div>
                    <div className="order-1 order-lg-2 col-lg-8 col-md-12 col-sm-12 col-12">
                        <div className="advance-tab-content advance-tab-content-1">
                            <TabPanel>
                                <div className="tab-content">
                                    <Elrond />
                                </div>
                            </TabPanel>
                            
                            <TabPanel>
                                <div className="tab-content">
                                    <Azero />
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </Tabs>
        </div>
    )
}
export default ValidatorsTab;