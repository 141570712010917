import React from 'react';
import SEO from "../common/SEO";
import Breadcrumb from "../elements/breadcrumb/Breadcrumb";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import ServiceStaking from '../elements/service/ServiceStaking';
import Separator from '../elements/separator/Separator';
import StakingInfra from '../elements/about/StakingInfra';
import ValidatorsTab from '../elements/advancetab/ValidatorsTab';
import ValidatorLayout from '../common/ValidatorLayout';

const Validators = () => {
    return (
        <>

            <SEO title="Validators" />
            <ValidatorLayout>
                <Breadcrumb 
                    title="Non-Custodial Staking Services for Everyone."
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Staking"
                />
                <div className="main-content">

                    {/* Start Service Area  */}
                    <div className="rn-service-area rn-section-gap" id='validatorServices'>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Time is Money"
                                        title = "Why You Should Delegate To Us"
                                        description = "Take back control of your precious time and spend it on what matters most."
                                     />
                                </div>
                            </div>
                            <ServiceStaking 
                                serviceStyle = "service__style--1 bg-color-blackest radius mt--25"
                                textAlign = "text-center"
                            />
                        </div>
                    </div>
                    {/* End Service Area  */}

                    <Separator />

                    {/* Start Elements Area  */}
                    <div className="rwt-advance-tab-area rn-section-gap" id='networks'>
                        <div className="container">
                            <div className="row mb--40">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Our nodes"
                                        title = "We are involved in the best-in-class Technologies."
                                        description = "Our validators help secure the most promising proof of stake networks in the entire crypto space."
                                    />
                                </div>
                            </div>
                            <ValidatorsTab />
                        </div>
                    </div>
                    {/* End Elements Area  */}

                    <Separator />

                    <StakingInfra />
                </div>

            </ValidatorLayout>
            
        </>
    )
}

export default Validators;
