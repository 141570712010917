import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'


// Import Css Here 
import './assets/scss/style.scss';
import Validators from './pages/Validators';
import MainPage from './pages/MainPage';


const App = () => {
    return (
        <Router>
            <PageScrollTop>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={MainPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/staking"}`} exact component={Validators}/>
                </Switch>
            </PageScrollTop>
        </Router>
    )
}


export default App
