import React from 'react';
import Typed from 'react-typed';
import AccordionStakingInfra from '../accordion/AccordionStakingInfra';

const StakingInfra = () => {
    return (
        <div className="rn-company-mission-are rn-section-gap" id='specs'>
            <div className="container">
                <div className="row row--30">
                    <div className="col-lg-6">
                        <div className="mission-title">
                            <h2 className="title">High End and Latest<br /> {" "}
                                <Typed className="theme-gradient"
                                    strings={[
                                        "Hardware.",
                                        "Software.",
                                    ]}
                                    typeSpeed={80}
                                    backSpeed={5}
                                    backDelay={1000}
                                    loop
                                />
                            </h2>
                            <p>We do not have no limits when it comes to investing in the latest most performant hardware for our validators. We aim to have a zero missed block policy while using best practices for our security and operations. Furthermore, our nodes are up and running 99.9% of the time and always up-to-date with the latest software releases and security patches.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 mt_md--30 mt_sm--30">
                        <AccordionStakingInfra customStyle="" />
                    </div>
                </div>
            </div>                        
        </div>
    )
}
export default StakingInfra;
