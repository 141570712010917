import React from 'react';
import ValidatorsFooter from './footer/ValidatorsFooter';
import ValidatorsHeader from './header/ValidatorsHeader';

const ValidatorLayout = ({children}) => {
    return (
        <>
            <main className="page-wrapper">
                <ValidatorsHeader btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />
                {children}
                <ValidatorsFooter />
            </main>
        </>
    )
}
export default ValidatorLayout;
