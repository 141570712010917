import React, { useState } from 'react';
import {Link} from "react-router-dom";

const ValidatorsNav = () => {

    const onClickSection = (e, id) => {
        e && e.preventDefault(); // to avoid the link from redirecting
        const elementToView = document.getElementById(id);
        elementToView.scrollIntoView();
        handleClick();
    }

    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    return (
        <ul className="mainmenu">
            <li><Link to="#validatorServices" onClick={(e=>onClickSection(e, 'validatorServices'))}>Why Us</Link></li>
            
            <li><Link to="#networks" onClick={(e=>onClickSection(e, 'networks'))}>Our Networks</Link></li>

            <li><Link to="#specs" onClick={(e=>onClickSection(e, 'specs'))}>Our Infrastructure</Link></li>

            <li><Link to="#contact" onClick={(e=>onClickSection(e, 'contact'))}>Contact</Link></li>
        </ul>
    )
}
export default ValidatorsNav;
