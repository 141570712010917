import React from 'react';
import { FiDollarSign, FiTool, FiUser } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <FiTool />,
        title: 'Expertise',
        description: 'We have over 10 years of experience in Software Engineering and Infrastructure. We always apply industry best practices when it comes to maintaining and securing our validators.'
    },
    {
        icon: <FiUser />,
        title: '24/7 Support',
        description: 'In case of any questions, doubts or insecurities regarding staking, come chat with us in our official channels. We are also here to help for any questions regarding our supported projects.'
    },
    {
        icon: <FiDollarSign />,
        title: 'Passive Income',
        description: 'Be part of the biggest revolution since the internet bubble while helping secure your favorite network, earning some extra tokens and using your precious time for what truly matters.'
    },
]

const ServiceStaking = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceStaking;