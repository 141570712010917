import React from 'react';

const Azero = () => {
    return (
        <div className="rwt-about-area">
            <div className="container">
                <div className="row row--25 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/azero.png" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">Privacy-enhancing Blockchain</h2>
                                <p>Aleph Zero is a peer-reviewed DAG-based platform that’s scalable, private, and developer-friendly - Aleph Zero is a Proof-of-Stake public blockchain with private smart contracts built from the first principles. It combines an original consensus protocol with a privacy framework based on ZK-SNARKs and sMPC with the Substrate stack.</p>
                                <div className="read-more-btn mt--40">
                                    <a className="btn-default" href="https://azero.dev/#/staking" target="_blank" rel="noreferrer"><span>Our Validators (Coming Soon)</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default Azero
