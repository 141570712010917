import React from 'react';
import ValidatorsCalltoAction from '../../elements/calltoaction/ValidatorsCalltoAction';
import MainCopyright from './MainCopyright';
import ScrollTop from "./ScrollTop";


const ValidatorsFooter = () => {
    return (
        <>
            <div className="footer-style-2" id='contact'>
                <ValidatorsCalltoAction />
                <MainCopyright />
            </div>
            <ScrollTop />
        </>
    )
}
export default ValidatorsFooter;