import React, { useState } from "react";
import SEO from "../common/SEO";
import {Link} from "react-router-dom";


import Typed from 'react-typed';
import CircleProgress from "../elements/progressbar/CircleProgress";

import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import { FiArrowDown, FiArrowRight } from "react-icons/fi";
import MainPageHeader from "../common/header/MainPageHeader";
import CowrybaseServices from "../elements/service/CowrybaseServices";
import MainFooter from "../common/footer/MainFooter";


const MainPage = () => {
    const onClickSection = (e, id) => {
        e && e.preventDefault(); // to avoid the link from redirecting
        const elementToView = document.getElementById(id);
        elementToView.scrollIntoView();
        handleClick();
    }

    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    return (
        <>
            <SEO title="Home" />
            <main className="page-wrapper">
                
                <MainPageHeader btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />


                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent variation-2 height-750">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                                <div className="inner text-start">
                                    <h1 className="title theme-gradient display-two">Infrastructure <br /> for {" "}
                                        <Typed
                                            strings={[
                                                "Blockchain.",
                                                "Web3.",
                                                "Crypto.",
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        />
                                    </h1>
                                    <p className="description">We design and develop software for clients, with a focus on Smart Contracts. We also run validator nodes on major PoS networks</p>
                                    <div className="button-group">
                                        <a className="btn-default btn-large round btn-icon" href="#services" onClick={(e=>onClickSection(e, 'services'))}>Our Services <FiArrowDown /></a>
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="/staking">Our Validators <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src="./images/bg/blockchain.png" alt="Banner Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap" id="services">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What we can do for you"
                                    title = "Our services."
                                    description = ""
                                    />
                            </div>
                        </div>
                        <CowrybaseServices 
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--20 rbt-border"
                            textAlign = "text-start"
                            />
                    </div>
                </div>
                {/* End Service Area  */}


                <Separator />
                <div className="rwt-progressbar-area rn-section-gap" id="expertise">
                    <div className="container">
                        <div className="row mb--25">
                            <div className="col-lg-10 offset-lg-1">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Our Expertise."
                                    title = "Where we can help."
                                    description = "Our team brings the following skills to the table after many years of experience."
                                />
                            </div>
                        </div>
                        <div className="col-lg-10 offset-lg-1">
                            <CircleProgress />
                        </div>
                    </div>
                </div>

                <MainFooter />

            </main>
        </>
    )
}

export default MainPage;
