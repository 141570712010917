import React from 'react';
import MainCalltoAction from '../../elements/calltoaction/MainCallToAction';
import MainCopyright from './MainCopyright';
import ScrollTop from "./ScrollTop";


const MainFooter = () => {
    return (
        <>
            <div className="footer-style-2" id='contact'>
                <MainCalltoAction />
                <MainCopyright />
            </div>
            <ScrollTop />
        </>
    )
}
export default MainFooter;